import React from 'react';
import PropTypes from 'prop-types';
import { TransText } from './Translation';

/**
 * This component is shown to the user after they have completed reviewing the quiz questions and answers
 */
export function ReviewDonePane(props) {
	return (
		<div className="review-done-pane">
			<h5 className="mt-4">
				<TransText transKey="review-complete-compliments" lang={props.lang} />
			</h5>
			<p className="mt-4">
				<TransText transKey="review-complete" lang={props.lang} />
			</p>
			<p className="mt-4">
				<TransText transKey="review-incomplete" lang={props.lang} />
			</p>
		</div>
	);
}

ReviewDonePane.propTypes = {
	lang: PropTypes.string.isRequired,
};