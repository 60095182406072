export const enTrans = {
	// buttons
	"button-next": "Next",
	"button-back": "Go Back",
	"button-next-quiz": "Submit Answer",
	"button-complete-quiz": "Submit Responses and Complete Course",
	"button-complete-review": "Complete Course",
	"button-complete-survey": "Submit Responses and Finish",
	// app name
	"app-name": "Hemingway",
	"app-subtitle": "a scam and phishing challenge by Norton Research",
	// welcome titles
	"welcome-title": "Welcome!",
	"welcome-back-title": "Welcome Back!",
	// language select
	"lang-select-text": "Please select your language to begin",
	// pre-welcome pane (age group)
	"start-age-group": "Before we get started, please select your age group.",
	"under-25": "I am 50 years old or younger",
	"over-25": "I am over 50 years old",
	// welcome pane
	"intro-text-old": "Your good friend and neighbor, Barbara Richards, often asks you to keep an eye on her home when she is away. She is traveling out of the country for several weeks without access to her computer and has entrusted you to take care of her affairs while she's away. She has given you the keys to her home, where she keeps a notebook with her passwords for various accounts.",
	"intro-text-young": "Your great aunt, Barbara Richards, lets you use her house and her computer when she's out of town.  This time, she's traveling for several weeks, so she asked you to watch her inbox and take care of any email that needs attention. She also showed you where she keeps a notebook with the passwords for her various accounts.",
	"intro-text-2": "Your task today is to use her computer to handle email for her. She plans to give you a phone call later to check in, and you can let her know of anything important that comes up.",
	"intro-pane-continue": 'Click "Next" to get started.',
	"legal-blurb": "All emails shown here, both scams and legitimate correspondence, are taken from real people’s inboxes and modified only to remove any personal information and fit in the quiz format.  Brand names, logos, trademarks and service marks that appear in the emails are the property of their respective companies, are not affiliated with NortonLifeLock, and are included here solely for educational purposes.",
	// resume-pane
	"resume-pane-text": "It looks like you already started the course.  Would you like to continue where you left off?",
	"resume-pane-clear": "Clear previous responses and start again",
	"resume-pane-continue": "Resume where I left off",
	// email pane
	"default-question-title": "Barbara received the following email",
	"email-reply-button": "Reply",
	"email-forward-button": "Forward",
	"email-delete-button": "Delete",
	"email-from-field": "From:",
	"email-to-field": "To:",
	// quiz pane
	"quiz-answer-followup-explain": "Explain",
	"quiz-answer-followup-why": "Why?",
	"quiz-answer-followup-how": "How?",
	"quiz-choose-1-answer": "Choose one answer",
	"links-disabled-msg": "Links disabled for Hemingway. Normally this would have opened a new web page.",
	"links-attachments-disabled-msg": "Links and attachments disabled for Hemingway. This click would download ",
	// quiz complete pane
	"quiz-complete-1": "You've completed all the exercises in the Scam Defender Obstacle Course.",
	"quiz-complete-review": "Next, you will review the exercises and see which ones were scams. All of the emails, both the legitimate ones and the scams, are examples from the real world. During the review, you will learn what aspects help to indicate whether each email is legitimate.",
	"quiz-complete-compliments": "Congratulations!",
	// review pane
	"review-expl-title": "Correct Answer Explanation",
	// review complete pane
	"review-complete-compliments": "Excellent!",
	"review-complete": "You've completed reviewing which emails were scams and which were legit.",
	"review-incomplete": "We just have a few more questions for you.  Please click \"Next\" to proceed.",
	// review error
	"quiz-complete-error-submit-1": "There was a problem submitting responses, please ask for help.",
	"quiz-complete-error-submit-2": "There was a problem submitting your survey responses.",
	// thanks pane
	"thanks-pane-1": "Thanks for participating! We hope you found this course helpful.",
	"user-feedback-ask": "If you want, please leave any feedback below. Otherwise you can close this window.",
	"user-feedback-placeholder": "please enter your feedback here",
	"user-feedback-submitted-success": "Thank you, your feedback has been submitted",
	"user-feedback-submitted-error": "There was an error submitting your feedback",
	"user-feedback-submit-btn": "Submit Feedback",
	// thanks pane email signup (not currently supported)
	"user-email-signup-text": "If you enjoyed Hemingway, you can optionally sign up to receive updates on further scam-related research by Norton. Your email will not be used for any other purposes.",
	"user-email-signup-btn": "Register",
	// survey
	"survey-instructions-0": ("We at Norton Research Group are studying what scams are hardest for people to identify. " +
			"If you let us use the responses you entered in the previous exercise, you'll help us to develop countermeasures " +
			"that we hope will help people avoid being taken advantage of by unscrupulous scammers.  We are not collecting your " +
			"name or any other identifying information, so your participation in the study would be completely anonymous."),
	"survey-instructions-1": "To contextualize the findings of our research, we would like to ask you a few demographic questions, as well as questions to understand your habits when using computing devices.",
	"survey-instructions-2": "Again, your answers are anonymous and can only be accessed by researchers from our group.",
	"survey-instructions-3": "Answering the following questions is, of course, optional. You may choose to skip any or all of the questions.",
	"survey-consent-q":  "Can we use your responses on the Hemingway quiz in our research study?",
	"survey-consent-yes": "Yes",
	"survey-consent-no": "No"
};
