import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { fetch_quiz_content, reviveMyTypes } from './QuestionsAndAnswers.js';
import { Survey } from './Survey';
import { LanguageSelector } from './LanguageSelector';
import { ResumePane } from './ResumePane';
import { PreWelcomePane } from './PreWelcomePane';
import { WelcomePane } from './WelcomePane';
import { QuizDonePane } from './QuizDonePane';
import { ReviewDonePane } from './ReviewDonePane';
import { ThanksPane } from './ThanksPane';
import { translateString, TransText } from './Translation';
import { Config } from './config';
import { LegalBlurb } from './LegalBlurb';
import { postQuizResponses, postSurveyResponses } from './api';

function QuizHeader(props) {
	const isCompact = props.isCompact || false;
	const classes = ["quiz-header"];
	if (isCompact) {
		classes.push("quiz-header-sm");
	} else {
		classes.push("quiz-header-lg");
	}

	return (
		<div className={classnames(classes)}>
			<h1 className="app-title-primary font-weight-light m-0 p-0">
				<TransText transKey="app-name" lang={props.lang} />
			</h1>
			{isCompact ? null :
				<h2 className="app-subtitle font-weight-light mb-3 mt-3">
					<TransText transKey="app-subtitle" lang={props.lang}></TransText>
				</h2>}
			<hr />
		</div>
	);
}

QuizHeader.propTypes = {
	lang: PropTypes.string.isRequired,
	/**
	 * isCompact is not required and set to false when not provided
	 */
	isCompact: PropTypes.bool,
};

QuizHeader.defaultProps = {
	isCompact: false,
};

function QuizFooter() {
	return (
		<Container><Row><Col md="12"><div className="border" /></Col></Row></Container>
	);
}

function QuizNavigation(props) {
	return (
		<ButtonToolbar>
			<Button variant="outline-secondary" size="lg" className="back-btn mr-4 mb-2"
				onClick={props.onBack}
				disabled={!props.backEnabled}>
				<TransText transKey="button-back" lang={props.lang} />
			</Button>

			<Button size="lg" variant="primary" className="mr-4 mb-2"
				disabled={!props.submitEnabled} onClick={props.onSubmit}>
				{props.submitButtonText}
			</Button>
		</ButtonToolbar>
	);
}

QuizNavigation.propTypes = {
	/**
	 * User-selected language
	 */
	lang: PropTypes.string.isRequired,
	onBack: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	backEnabled: PropTypes.bool.isRequired,
	submitEnabled: PropTypes.bool.isRequired,
	submitButtonText: PropTypes.string.isRequired,
};

function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
		return v.toString(16);
	});
}

/**
 * enum representing the different quiz stages
 */
const QuizStage = {
	/**
	 * Select language (very first stage)
	 */
	LANG_SELECT: -3,
	/**
	 * This stage is a little misnamed. It's the welcome screen and also includes age selection.
	 */
	PRE_WELCOME: -2,
	/**
	 * This stage is a little misnamed
	 * It refers to the intro dialog introducing Barbara
	 */
	WELCOME: -1,
	/**
	 * User is answering questions
	 */
	QUIZ: 0,
	/**
	 * User is presented with the QuizDonePane
	 */
	QUIZ_COMPLETE: 1,
	/**
	 * User is reviewing their quiz answers in read-only mode with answers shown
	 */
	REVIEW: 2,
	/**
	 * User is presented with the ReviewDonePane
	 */
	REVIEW_COMPLETE: 3,
	/**
	 * Optionally at the end the user is shown a survey
	 */
	SURVEY: 4,
	/**
	 * Final screen
	 */
	COMPLETE: 5,
};

/**
 * Keep all the state fields here so can be referenced in save/restore operations consistently
 */
const QUIZ_STATE_FIELDS = [
	/**
	 * Stage of the quiz
	 */
	'surveyStage',
	/**
	 * The question the user is currently viewing
	 */
	'currentQuestion',
	/**
	 * Whether the quiz portion has been successfully submitted to the server
	 */
	'successfullySubmitted',
	/**
	 * The selected questions to use for this user
	 */
	'questions',
	/**
	 * The user's responses
	 */
	'responses',
	/**
	 * The user's selected language
	 */
	'lang',
	/**
	 * The user's selected age group
	 */
	'ageGroup',
	/**
	 * Whether the survey portion has been successfully submitted to the server
	 * Also set to false when the survey has not yet been submitted
	 */
	'successfullySubmittedSurvey',
	/**
	 * Error after submitting survey
	 */
	'surveySubmitError',
	/**
	 * Array of timestamps at which each question was started
	 */
	'questionStartTimestamps',
	/**
	 * Array of timestamps at which each question was completed
	 */
	'questionEndTimestamps',
];

/**
 * state:
 * 		- surveyStage: int
 * 		- checkOnResume: bool
 * 		- lang: string
 */
export class Quiz extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.getStoredInfo();

		// bind because javascript is terrible
		this.fetchQuizQuestions = this.fetchQuizQuestions.bind(this);
		this.updateStoredInfo = this.updateStoredInfo.bind(this);
		this.getStoredInfo = this.getStoredInfo.bind(this);
		this.updateResponse = this.updateResponse.bind(this);
		this.submitQuizResponses = this.submitQuizResponses.bind(this);
		this.goNext = this.goNext.bind(this);
		this.restart = this.restart.bind(this);
		this.goBack = this.goBack.bind(this);
		this.surveyDone = this.surveyDone.bind(this);
		this.handleSelectLang = this.handleSelectLang.bind(this);
		this.getCurrentContentPane = this.getCurrentContentPane.bind(this);
	}

	componentDidMount() {
		if (this.state.lang && (!this.state.questions || this.state.questions.length === 0)) {
			this.fetchQuizQuestions();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// use this to update the timings for the questions
		if (prevState.currentQuestion !== this.state.currentQuestion) {
			// question has changed
			console.log(`Question has changed from ${prevState.currentQuestion} to ${this.state.currentQuestion}. Potentially updating timings.`);

			// has the *previous* question been marked as completed?
			if (!this.state.questionEndTimestamps[prevState.currentQuestion]) {
				// not yet
				const newTimestamps = [...this.state.questionEndTimestamps];
				newTimestamps[prevState.currentQuestion] = Date.now();
				this.setState({
					questionEndTimestamps: newTimestamps,
				});
			}

			// has the *current* question been marked as started?
			if (!this.state.questionStartTimestamps[this.state.currentQuestion]) {
				const newTimestamps = [...this.state.questionStartTimestamps];
				newTimestamps[this.state.currentQuestion] = Date.now();
				this.setState({
					questionStartTimestamps: newTimestamps,
				});
			}
		} else if (prevState.surveyStage !== this.state.surveyStage && prevState.surveyStage === QuizStage.QUIZ) {
			console.log(`Stage has changed from ${prevState.surveyStage} to ${this.state.surveyStage}. Potentially updating timings.`);

			// has the *current* question been marked as completed?
			if (!this.state.questionEndTimestamps) {
				console.warn('questionEndTimestamps is not set at all for some reason');
				const newTimestamps = [];
				newTimestamps[this.state.currentQuestion] = Date.now();
				this.setState({
					questionEndTimestamps: newTimestamps,
				});
			} else if (!this.state.questionEndTimestamps[this.state.currentQuestion]) {
				// not yet
				const newTimestamps = [...this.state.questionEndTimestamps];
				newTimestamps[this.state.currentQuestion] = Date.now();
				this.setState({
					questionEndTimestamps: newTimestamps,
				});
			}
		}
	}

	/**
	 * Get the quiz questions (often in response to language changes)
	 */
	async fetchQuizQuestions() {
		if (!this.state.lang) {
			throw new Error('language must be set to fetch quiz questions');
		}

		console.debug(`Fetching quiz questions with language set to ${this.state.lang}...`);
		const qs = await fetch_quiz_content(this.state.lang);
		console.log("Received " + qs.length + " questions from server");
		console.log(JSON.stringify(qs));
		const numberedQuestions = qs.map((q, index) => {
			q._qidx = index;
			return q;
		});
		const scrambledQuestions = [];
		while (numberedQuestions.length > 0) {
			const next = Math.floor(Math.random() * numberedQuestions.length);
			scrambledQuestions.push(numberedQuestions[next]);
			numberedQuestions.splice(next, 1);
		}
		console.debug(`Loaded ${scrambledQuestions.length} questions from bank`);

		// now pick a subset
		if (Config.numQuestions > -1) {
			while (scrambledQuestions.length > Config.numQuestions) {
				scrambledQuestions.pop();
			}
			console.debug(`Reduced number of quiz questions to ${Config.numQuestions}`);
		}

		this.setState({
			questions: scrambledQuestions,
			responses: scrambledQuestions.map(q => q.getEmptyAnswer())
		});
	}

	updateStoredInfo(state) {
		if (! this.localStorageUnavailable) {
			console.log('updating localStorage...');
			const ls = window['localStorage'];
			QUIZ_STATE_FIELDS.map(f => (ls.setItem(f, JSON.stringify(state[f]))));
			const thisFields = ['endTimestamp'];
			thisFields.map(f => (ls.setItem(f, JSON.stringify(this[f]))));
			ls.setItem('endTimestamp', this.endTimestamp);
		}
	}

	/**
	 * NOTE: this is called by the constructor
	 * Return the new state
	 */
	getStoredInfo() {
		const ls = window['localStorage'];
		this.localStorageUnavailable = false;
		const newState = {};

		const thisFields = ['uuid', 'startTimestamp', 'endTimestamp'];
		const thisFieldDefaults = [uuidv4(), Date.now(), null];
		const stateFieldDefaults = {
			surveyStage: QuizStage.LANG_SELECT,
			// it's important for this to be -1
			currentQuestion: -1,
			successfullySubmitted: false,
			questions: [],
			responses: [],
			lang: null,
			ageGroup: null,
			successfullySubmittedSurvey: false,
			surveySubmitError: null,
			questionStartTimestamps: [],
			questionEndTimestamps: [],
		};

		//First set everything to defaults
		thisFields.map((f, i) => {
			this[f] = thisFieldDefaults[i];
		});
		QUIZ_STATE_FIELDS.map((fieldName) => {
			newState[fieldName] =  stateFieldDefaults[fieldName];
		});

		try {
			if (ls.getItem('uuid')) {
				thisFields.map(f => (this[f] =  JSON.parse(ls.getItem(f), reviveMyTypes)));
				QUIZ_STATE_FIELDS.map(f => (newState[f] =  JSON.parse(ls.getItem(f), reviveMyTypes)));
				console.log("Found stored info");
			} else {
				//we didn't have anything stored, so store the defaults
				thisFields.map((fieldName) => {
					ls.setItem(fieldName, JSON.stringify(this[fieldName]));
				});
				QUIZ_STATE_FIELDS.map((fieldName) => {
					if (fieldName === 'lang' && !newState[fieldName]) {
						// use null instead of undefined
						newState[fieldName] = null;
					}
					ls.setItem(fieldName, JSON.stringify(newState[fieldName]));
				});
				console.log("No info in local storage, creating starting state.");
			}
			console.log("Local Storage available");
		} catch(e) {
			// local storage not present
			console.error("Local storage unavailable, error was " + e);
			console.error(e);
			this.localStorageUnavailable = true;
		}

		newState.checkOnResume = newState.surveyStage > QuizStage.PRE_WELCOME;

		if (!newState.lang) {
			const searchParams = new URL(window.location.href).searchParams;
			const lang = searchParams.get('lang');
			if (lang) {
				newState.lang = lang;
				console.debug(`got language from URL -> ${lang}`);
			}
		}

		return newState;
	}

	updateResponse(r) {
		this.setState(
			(state) => {
				const updatedResponses = state.responses;
				updatedResponses[state.currentQuestion] = r;
				return { responses: updatedResponses };
			});
	}

	submitQuizResponses(state) {
		//unscramble responses
		this.unscrambledResponses = new Array(state.responses.length);
		state.questions.map((q, ridx) => {
			const r = state.responses[ridx];
			r.presentation_order = ridx;
			this.unscrambledResponses[q._qidx] = state.responses[ridx];
		});

		// record the order that the questions were presented to the user
		const questionOrder = state.questions.map((question) => {
			return { "qid": question.qid };
		});

		console.log("The scrambled questions:");
		console.log(state.questions);
		console.log("The scrambled responses:");
		console.log(state.responses);
		console.log("The responses to submit:");
		console.log(this.unscrambledResponses);

		this.endTimestamp = Date.now();
		const submission = {
			uuid: this.uuid,
			responses: this.unscrambledResponses,
			startTimestamp: this.startTimestamp,
			endTimestamp: this.endTimestamp,
			lang: this.state.lang,
			ageGroup: this.state.ageGroup,
			questionStartTimestamps: this.state.questionStartTimestamps,
			questionEndTimestamps: this.state.questionEndTimestamps,
			questionOrder: questionOrder,
			viewportDimensions: {
				width: window.innerWidth,
				height: window.innerHeight,
			},
		};
		console.log("Submitting the following responses:");
		console.log(JSON.stringify(submission));
		postQuizResponses(submission).then(resp => {
			console.log("Received response.");
			if (!resp.ok) {
				throw new Error('POST of submission results failed with ' + resp.status + ": " + resp.statusText);
			}
			return resp.json();
		}
		).then(respJson => {
			console.log("The json is " + JSON.stringify(respJson));
			this.setState({ successfullySubmitted: respJson.success });
		}).catch(err => {
			console.error("Submission failed with:");
			console.error(err);
		});
	}

	/**
	 * @param {any} options A dictionary that may optionally be passed
	 */
	goNext(options) {
		this.setState(
			(state) => {
				if (state.checkOnResume) {
					return { checkOnResume: false };
				}
				switch(state.surveyStage) {
					case QuizStage.LANG_SELECT:
						return { surveyStage: QuizStage.PRE_WELCOME };
					case QuizStage.PRE_WELCOME:
						if (!options.ageGroup) {
							throw new Error('ageGroup is required in goNext in the PRE_WELCOME stage');
						}
						return { surveyStage: QuizStage.WELCOME, ageGroup: options.ageGroup };
					case QuizStage.WELCOME:
						return {
							surveyStage: QuizStage.QUIZ,
							currentQuestion: 0,
						};
					case QuizStage.QUIZ:
						// we have just completed a question

						if (state.currentQuestion === state.questions.length - 1) {
							// we have completed the current question, which is the last question
							// TODO - this won't pick up the end timestamp for the final question
							this.submitQuizResponses(state);

							if (Config.interleaveAnswers) {
								// still need to review the last question
								return { surveyStage: QuizStage.REVIEW };
							} else {
								return { surveyStage: QuizStage.QUIZ_COMPLETE };
							}
						}
						if (Config.interleaveAnswers) {
							// instead of falling through and advancing the question,
							// we move onto the review stage
							return { surveyStage: QuizStage.REVIEW };
						}

						//continue to default behavior after switch
						break;
					case QuizStage.QUIZ_COMPLETE: {
						let nextCurrentQuestion = 0;
						while (nextCurrentQuestion < state.questions.length && state.questions[nextCurrentQuestion].isNonQuestion()) {
							nextCurrentQuestion++;
						}
						if (Config.interleaveAnswers) {
							return { surveyStage: QuizStage.REVIEW_COMPLETE, currentQuestion: 0 };
						} else {
							return { surveyStage: QuizStage.REVIEW, currentQuestion: nextCurrentQuestion};
						}
					}
					case QuizStage.REVIEW:
						if (state.currentQuestion === state.questions.length - 1) {
							if (Config.showSurvey) {
								return { surveyStage: QuizStage.REVIEW_COMPLETE, currentQuestion: 0 };
							} else {
								return { surveyStage: QuizStage.COMPLETE, currentQuestion: 0 };
							}
						}
						if (Config.interleaveAnswers) {
							// have we already answered the next question?
							const nextQuestion = state.currentQuestion + 1;
							if (nextQuestion in this.state.responses && ('answerIndex' in this.state.responses[nextQuestion]) &&
								this.state.responses[nextQuestion].answerIndex !== undefined) {
								// nextQuestion has already been answered
								// advance to the next question in the quiz, but in review mode
								return {
									currentQuestion: nextQuestion,
									surveyStage: QuizStage.REVIEW,
								};
							} else {
								// advance to the next question in the quiz
								return {
									currentQuestion: nextQuestion,
									surveyStage: QuizStage.QUIZ,
								};
							}
						}

						//continue to default behavior after switch
						break;
					case QuizStage.REVIEW_COMPLETE:
						if (Config.showSurvey) {
							return { surveyStage: QuizStage.SURVEY, currentQuestion: 0 };
						} else {
							return { surveyStage: QuizStage.COMPLETE, currentQuestion: 0 };
						}
					default:
						// we should never be here
						throw new Error(`unknown stage: ${state.surveyStage}`);
				}

				// if we are down here, then we are about to start a new question
				let nextQuestion = state.currentQuestion + 1;

				//If in review stage, skip over non questions (although there may not be any anymore)
				while (state.surveyStage === QuizStage.REVIEW &&
					nextQuestion < state.questions.length &&
					state.questions[nextQuestion].isNonQuestion()) {
					nextQuestion++;
				}

				return { currentQuestion: nextQuestion };
			},
			()=>{
				if (!Config.interleaveAnswers || this.state.surveyStage != QuizStage.REVIEW) {
					window.scroll(0, 0);
				}
			}
		);
	}

	restart() {
		localStorage.clear();
		window.location.reload();
	}

	/**
	 * This is the hook into the back button
	 */
	goBack() {
		if (this.state.checkOnResume) {
			this.restart();
			return;
		}
		this.setState(
			(state) => {
				if (state.surveyStage === QuizStage.PRE_WELCOME) {
					return { surveyStage: QuizStage.LANG_SELECT };
				} else if (state.surveyStage === QuizStage.REVIEW || state.surveyStage === QuizStage.QUIZ) {
					let nextQuestion = state.currentQuestion - 1;
					while (nextQuestion >= 0 && state.surveyStage === QuizStage.REVIEW && state.questions[nextQuestion].isNonQuestion()) {
						nextQuestion--;
					}
					if (nextQuestion < 0) {
						return {};
					}
					if (Config.interleaveAnswers) {
						// whenever we go back in interleaved mode, we *always* stay in the review stage
						return { currentQuestion: nextQuestion, surveyStage: QuizStage.REVIEW };
					} else {
						return { currentQuestion: nextQuestion };
					}
				} else if (state.surveyStage === QuizStage.WELCOME) {
					return { surveyStage: QuizStage.PRE_WELCOME };
				} else {
					throw new Error(`cannot go back when the stage is ${state.surveyStage}`);
				}
			}
		);
	}

	/**
	 * Called when the survey section is completed
	 */
	surveyDone(surveyState) {
		const submission = {
			uuid: this.uuid,
			lang: this.state.lang,
			surveyResponses: surveyState,
			quizStartTimestamp: this.startTimestamp,
			quizEndTimestamp: this.endTimestamp,
			surveyEndTimestamp: Date.now(),
		};
		console.log("Submitting the following survey responses:");
		console.log(JSON.stringify(submission));
		postSurveyResponses(submission).then(resp => {
			console.log("Received response.");
			if (!resp.ok) {
				throw new Error(`POST of submission results failed with ${resp.status}: ${resp.statusText}`);
			}
			return resp.json();
		}).then(respJson => {
			console.log("The json is " + JSON.stringify(respJson));
			this.setState({
				successfullySubmittedSurvey: respJson.success,
				surveySubmitError: null,
			});
		}).catch(err => {
			console.error("Submission failed with:");
			console.error(err);
			this.setState({
				successfullySubmittedSurvey: false,
				surveySubmitError: err.toString(),
			});
		});
		this.setState({ surveyStage: QuizStage.COMPLETE });
	}

	/**
	 * Select the language and move onto the next screen
	 * @param {string} lang Language selected by the user
	 */
	handleSelectLang(lang) {
		console.log(`language is set to ${lang}`);

		this.setState({
			lang: lang,
		}, () => {
			console.debug(`language is now ${this.state.lang}`);
			// NOTE: this is done asynchronously
			this.fetchQuizQuestions();
			// meanwhile we go to the age selection pane
			this.goNext();
		});
	}

	getCurrentContentPane(state) {

		// Update local storage
		this.updateStoredInfo(state);

		if (state.checkOnResume) {
			return <ResumePane
				lang={this.state.lang}
				onResume={this.goNext}
				onClear={this.goBack} />;
		}

		if (state.surveyStage === QuizStage.LANG_SELECT) {
			return (
				<div>
					<LanguageSelector
						// pass any language the user might have selected in the past
						// also possible language is in the URL bar
						// note that lang can be null here and we do catch that in the component
						lang={this.state.lang}
						onSelect={this.handleSelectLang} />
				</div>
			);
		} else if (state.surveyStage === QuizStage.QUIZ || state.surveyStage === QuizStage.REVIEW) {
			// NOTE: qa is of type QuizQuestion
			const qa = state.questions[state.currentQuestion % state.questions.length];
			return qa.render(
				// currentResponse
				state.responses[state.currentQuestion % state.questions.length],
				// responseUpdatedHandler
				this.updateResponse,
				// inReviewMode
				state.surveyStage === QuizStage.REVIEW,
				// qsize
				'h3',
				// lang
				this.state.lang,
			);
		} else if (state.surveyStage === QuizStage.PRE_WELCOME) {
			return <PreWelcomePane
				lang={this.state.lang}
				onYoung={() => this.goNext({ ageGroup: 'young' })}
				onOld={() => this.goNext({ ageGroup: 'old' })} />;
		} else if (state.surveyStage === QuizStage.WELCOME) {
			if (!this.state.ageGroup) {
				throw new Error('ageGroup must be set by the welcome stage');
			}
			return <WelcomePane ageGroup={this.state.ageGroup} lang={this.state.lang} />;
		} else if (state.surveyStage === QuizStage.QUIZ_COMPLETE) {
			return <QuizDonePane lang={this.state.lang} successfullySubmitted={state.successfullySubmitted} />;
		} else if (state.surveyStage === QuizStage.REVIEW_COMPLETE) {
			return <ReviewDonePane lang={this.state.lang} />;
		} else if (state.surveyStage === QuizStage.SURVEY) {
			return <Survey onComplete={this.surveyDone}
				lang={this.state.lang}></Survey>;
		} else if (state.surveyStage === QuizStage.COMPLETE) {
			return <ThanksPane lang={this.state.lang} uuid={this.uuid}
				successfullySubmittedSurvey={state.successfullySubmittedSurvey}
				surveySubmitError={state.surveySubmitError}
				isSurveyEnabled={Config.showSurvey}
				onRestart={Config.allowRestartAtEnd ? this.restart : null} />;
		} else {
			throw new Error(`unkown stage: ${state.surveyStage}`);
		}
	}

	render() {
		const qnum = this.state.currentQuestion;
		const lastNum = this.state.questions.length - 1;
		const showCompactHeader = this.state.surveyStage >= QuizStage.WELCOME;
		if (this.state.questions.length === 0 && this.state.lang) {
			return <h2>Loading content...</h2>;
		}
		const hideNav = this.state.checkOnResume ||
			this.state.surveyStage === QuizStage.SURVEY ||
			this.state.surveyStage === QuizStage.COMPLETE ||
			this.state.surveyStage === QuizStage.PRE_WELCOME ||
			this.state.surveyStage === QuizStage.LANG_SELECT;

		// NOTE: lang may not be set here so we provide a default value
		const lang = this.state.lang || 'en';
		let nextButtonText = translateString(lang, 'button-next');
		if (qnum === lastNum && this.state.surveyStage === QuizStage.QUIZ) {
			nextButtonText = translateString(lang, 'button-complete-quiz');
		} else if (qnum === lastNum && this.state.surveyStage === QuizStage.REVIEW) {
			nextButtonText = translateString(lang, 'button-complete-review');
		} else if (this.state.surveyStage === QuizStage.QUIZ && Config.interleaveAnswers) {
			nextButtonText = translateString(lang, 'button-next-quiz');
		}

		return (
			<Container>
				<Row>
					<Col className="mt-2">
						<QuizHeader isCompact={showCompactHeader} lang={lang} />
					</Col>
				</Row>
				<Row>
					<Col className="my-1">
						{this.getCurrentContentPane(this.state)}
					</Col>
				</Row>
				<Row>
					<Col className="my-2" sm={true}>
						<div className={ hideNav ? "d-none" : ""} >
							<QuizNavigation
								onSubmit={this.goNext}
								submitEnabled={this.state.surveyStage !== QuizStage.QUIZ || (qnum <= lastNum && this.state.responses[qnum].isComplete()) }
								submitButtonText={nextButtonText}
								backEnabled={qnum !== 0}
								onBack={this.goBack}
								lang={lang} />
						</div>
					</Col>
				</Row>
				{ this.state.surveyStage === QuizStage.WELCOME ?
					<LegalBlurb lang={this.state.lang} /> : null}
				<Row>
					<QuizFooter />
				</Row>
			</Container>
		);
	}
}

export default Quiz;
