import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

import { TransText } from './Translation';

/**
 * Expected props:
 *  - onSelect: handle the language selection
 */
export function LanguageSelector (props) {
	const lang = props.lang || 'en';

	return (<div className="lang-select-pane">
		{/* NOTE: since the user has not selected a language here, we probably shouldn't translate this bit */}
		<p><TransText lang={lang} transKey="lang-select-text" /></p>
		<div>
			<ButtonToolbar>
				<div className="lang-en">
					<Button className="m-3 lang-select-btn" size="lg" variant="outline-primary"
						onClick={() => props.onSelect('en')}>English</Button>
				</div>
				<div className="lang-de">
					<Button className="m-3 lang-select-btn" size="lg" variant="outline-primary"
						onClick={() => props.onSelect('de')}>Deutsch</Button>
				</div>
			</ButtonToolbar>
		</div>
	</div>);
}

LanguageSelector.propTypes = {
	/**
	 * The user's language, which may or may not be set at this point
	 */
	lang: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
};

LanguageSelector.defaultProps = {
	/**
	 * Default language is english if it isn't set
	 */
	lang: 'en',
};
