import React from 'react';
import { TransText } from './Translation';
import PropTypes from 'prop-types';


export function LegalBlurb(props) {
	return <div className='legal-blurb mt-4'>
		{/* <h6>Legal</h6> */}
		<p className="text-secondary text-sm">
			<TransText lang={props.lang} transKey="legal-blurb" />
		</p>
	</div>;
}

LegalBlurb.propTypes = {
	lang: PropTypes.string.isRequired,
};