import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

import { TransText } from './Translation';

/**
 * Expected props:
 *      - lang: string
 *      - onYoung: function
 *      - onOld: function
 */
export function PreWelcomePane(props) {
	return (<div className="pre-welcome-pane">
		<p>
			<TransText transKey="start-age-group" lang={props.lang}></TransText>
		</p>
		<ButtonToolbar>
			<Button variant="outline-primary" size="lg" className="m-3 age-select-btn" onClick={props.onYoung}>
				<TransText transKey="under-25" lang={props.lang}></TransText>
			</Button>

			<Button variant="outline-primary" size="lg" className="m-3 age-select-btn" onClick={props.onOld}>
				<TransText transKey="over-25" lang={props.lang}></TransText>
			</Button>
		</ButtonToolbar>
	</div>);
}

PreWelcomePane.propTypes = {
	lang: PropTypes.string.isRequired,
	onYoung: PropTypes.func.isRequired,
	onOld: PropTypes.func.isRequired,
};