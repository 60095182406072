/**
 * We need this function because of how JavaScript computes boolean values
 * Note that process.env will generally return undefined for undefined environment variables and a string otherwise
 * So for example X=FALSE will be parsed as 'FALSE' by node
 * And Boolean('FALSE') === true in javascript
 * Therefore we need this function to deliberately pass 'false' and '0'
 *
 * The same is true for X=0 -> parsed as '0' by node (process.env)
 * Boolean('0') === true in javascript
 */
function booleanFromString(s) {
	if (!s) {
		// undefined, null, empty string, etc.
		return false;
	}

	if (s === 'false' || s === '0') {
		return false;
	}

	return true;
}

/**
 * Some configuration for the quiz
 * For now we'll put it in this file and later on we can move it somewhere else
 */
export const Config = {
	/**
	 * Whether to show the survey at the end
	 */
	showSurvey: true,

	/**
	 * Whether to allow the user to restart at the end
	 */
	allowRestartAtEnd: false,
	/**
	 * How many quiz questions to actually show the user
	 * If this is set to -1, show all of them
	 */
	numQuestions: 7,
	/**
	 * Whether to show answers at the end or to show feedback immediately after a user submits their answer
	 */
	interleaveAnswers: true,
	/**
	 * URL of the server to which to submit responses
	 * NOTE: make sure there is no trailing slash
	 * NOTE: create-react-app will read process.env as per https://create-react-app.dev/docs/adding-custom-environment-variables/
	 */
	// serverUrl: 'http://localhost:1717',
	serverUrl: process.env.REACT_APP_BACKEND_SERVER_URL || 'https://api.hemingway.nlok-research.me',

	/**
	 * Whether to ping the backend server when Hemingway starts
	 * Just as a test to make sure that it's up
	 */
	pingBackendServerOnStartup: booleanFromString(process.env.REACT_APP_PING_BACKEND_SERVER_ON_STARTUP) || false,

	/**
	 * True iff follow-up questions are enabled
	 */
	isFollowupEnabled: false,

	/**
	 * True iff we want to solicit user feedback about their experience
	 */
	showUserFeedbackBox: true,
	/**
	 * True iff we want users to enroll in future Norton phishing research
	 */
	showUserEmailEnrollmentForm: false,
};

export default Config;