import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { TransText, translateString } from './Translation';


export class EmailPane extends React.Component {
	/**
	 * Expected props:
	 * 		(specific to the question)
	 * 		- quid: number
	 *
	 * 		(specific to the email)
	 * 		- attachment
	 * 		- title
	 *      - subject
	 * 		- sender
	 *      - recipient
	 *      - bodyurl
	 *
	 *      (specific to the user)
	 *      - lang (string)
	 */
	constructor(props) {
		console.log('CONSTRUCT!');
		super(props);
		this._type = 'EmailPane';

		this.setEmailToDefaultScale = this.setEmailToDefaultScale.bind(this);
		this.scaleEmail = this.scaleEmail.bind(this);
	}

	static revive(key, val) {
		return <EmailPane {...val} />;
	}

	setEmailToDefaultScale() {
		const emailFrame = document.getElementById('email_iframe');
		if (emailFrame) {
			emailFrame.style.width = '100%';
			emailFrame.style.transform = '';
			emailFrame.style.transformOrigin = '';
			emailFrame.style.marginBottom = '';
		}
	}

	// Scale down email so its width fits, making it much nicer to look at on small screens
	scaleEmail() {
		const emailFrame = document.getElementById('email_iframe');
		if (emailFrame) {
			const frameRect = emailFrame.getBoundingClientRect();
			let scale = frameRect.width / emailFrame.contentDocument.body.scrollWidth;
			if (scale < 1) {
				// shrink even a bit more to ensure that it doesn't scroll horizontally
				scale = scale * 0.9;
				// Make the iframe big enough to fit the document width, then 
				// scale the whole thing down to fit in its assigned space
				emailFrame.style.width = `${100 / scale}%`;
				emailFrame.style.transform = `scale(${scale})`;
				emailFrame.style.transformOrigin = '0 0';
				// The height of the iframe is reduced by the scale, we need to add a
				// negative bottom margin so that it fills the space originally 
				// allotted to it. NB: the margin is scale too! So we have to 
				// do -(h*(1-scale))/scale which simplifies to h-(h/scale) 
				emailFrame.style.marginBottom = `${frameRect.height - (frameRect.height / scale)}px`;
				// Define a variable inside the frame in case styles need to use it.
				emailFrame.contentWindow.hemFrameScale = scale;
				console.log(`Got email frame, resizing with scale ${scale} = ${frameRect.width} / ${emailFrame.contentDocument.body.scrollWidth}`);
			}
		}
	}

	componentDidMount() {
		// Give the email document some time to load in the iframe, then scale things.
		// console.log("DID MOUNT");
		setTimeout(this.scaleEmail, 500);

		// also set up message listener
		window.addEventListener('message', (event) => {
			if (event.data && event.data.source === 'child-iframe' && event.data.msg_type === 'link-clicked') {
				// console.log(`Received message from child:`);
				// console.log(event.data);
				const text = translateString(this.props.lang, 'links-disabled-msg');
				alert(text);
			}
		});
	}

	componentDidUpdate() {
		// console.log('DID UPDATE');
		// We must reset the frame to default scale before taking measurements
		// in scaleEmail, otherwise we just keep getting the widest content width
		// encountered so far.
		this.setEmailToDefaultScale();
		setTimeout(this.scaleEmail, 500);
	}

	componentWillUnmount() {
		console.log("WILL UNMOUNT");
	}

	render() {
		let attach = <></> ;
		if (this.props.attachment) {
			attach = <div>
				<img style={{"maxHeight": "2em"}} className="mr-2" src="/img/paperclip-24.png"/>
				<a href={this.props.attachment} onClick={(e) => {
					e.preventDefault();
					window.alert(translateString(this.props.lang, "links-attachments-disabled-msg") + this.props.attachment);
					return false;
				}}>{this.props.attachment}</a>
			</div>;
		}

		const titleComp = <p className="quiz-question-flavor-text mb-3">
			{ this.props.title ?
				<span>{ this.props.title }</span> :
				<TransText transKey="default-question-title" lang={this.props.lang} />
			}
		</p>;

		return (
			<div className="email-pane">
				{titleComp}
				<div className="border border-primary rounded d-flex flex-column iframe__container--height" >
					<div className="border-bottom border-secondary d-none">
						<ButtonToolbar>
							<Button variant="info" size="sm" className="m-1">Reply</Button>
							<Button variant="info" size="sm" className="m-1">Forward</Button>
							<Button variant="danger" size="sm" className="m-1">Delete</Button>
						</ButtonToolbar>
					</div>
					<div className="border-bottom border-secondary p-2 bg-light rounded">
						<h4 className="email-subject">{this.props.subject}</h4>
						<h6>
							<TransText lang={this.props.lang} transKey="email-from-field" />
							<span>&nbsp;{this.props.sender}</span>
						</h6>
						<h6>
							<TransText lang={this.props.lang} transKey="email-to-field" />
							<span>&nbsp;{this.props.recipient}</span>
						</h6>
						{attach}
					</div>
					<div id="email_body_div" className="p-2 flex-grow-1 d-flex flex-column">
						{this.props.body || (<iframe id="email_iframe" className="border-0 flex-grow-1" src={this.props.bodyurl} style={{height:'100%', width:'100%'}}  />)}
					</div>
				</div>
			</div>
		);
	}
}

EmailPane.propTypes = {
	/**
	 * Attachment file name
	 */
	attachment: PropTypes.string,
	/**
	 * Flavor text for the question (not a property of the email)
	 */
	title: PropTypes.string,
	/**
	 * Language selected by the user.
	 * This one is required for i18n
	 */
	lang: PropTypes.string.isRequired,
	/**
	 * email FROM field
	 */
	sender: PropTypes.string.isRequired,
	/**
	 * email subject
	 */
	subject: PropTypes.string.isRequired,
	/**
	 * email TO field
	 */
	recipient: PropTypes.string.isRequired,

	/**
	 * Can include the email body directly as a string
	 */
	body: PropTypes.string,

	/**
	 * More common: include a link to the body of the email
	 * Will be displayed in an iframe
	 */
	bodyurl: PropTypes.string,
};