import React from 'react';
import PropTypes from 'prop-types';
import { TransText } from './Translation';

/**
 * This component is shown to the user after they have completed answering the quiz questions
 */
export function QuizDonePane(props) {
	return (
		<div className="quiz-done-pane">
			<h5 className="mt-4">
				<TransText transKey="quiz-complete-compliments" lang={props.lang} />
			</h5>
			<p className="mt-4">
				<TransText transKey="quiz-complete-1" lang={props.lang} />
			</p>
			<p className="mt-4">
				<TransText transKey="quiz-complete-review" lang={props.lang} />
			</p>

			<p className={"text-danger mt-4 " + (props.successfullySubmitted ? " d-none " : "")}>
				<TransText transKey="quiz-complete-error-submit-1" lang={props.lang} />
			</p>
		</div>
	);
}

QuizDonePane.propTypes = {
	lang: PropTypes.string.isRequired,
	successfullySubmitted: PropTypes.bool.isRequired,
};