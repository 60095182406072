import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { enTrans } from './locales/en';
import { deTrans } from './locales/de';

const locales = {
	en: enTrans,
	de: deTrans,
};

const DEFAULT_LANG = 'en';

/**
 * This just looks up a string and returns its translation
 * It does *not* return a component
 *
 * @param {string} lang Can be null
 * @param {string} transKey
 */
export function translateString(lang, transKey) {
	lang = lang || DEFAULT_LANG;

	if(!(lang in locales)) {
		throw new Error(`locale ${lang} is not found in locales folder`);
	}

	const regionalDB = locales[lang];

	if (!(transKey in regionalDB)) {
		throw new Error(`translation key ${transKey} not found for locale ${lang}`);
	}
	const text = regionalDB[transKey];
	return text;
}

/**
 * This is an actual React FC (component)
 *
 * This component translates text given a key into the given language
 * Expected props:
 *      - lang: string (may be null)
 *      - transKey: string
 */
export function TransText(props) {
	const lang = props.lang || DEFAULT_LANG;
	const text = translateString(lang, props.transKey);

	const className = classnames([
		`trans-lang-${lang}`,
		`trans-key-${props.transKey}`
	]);
	return <span className={className}>
		{ text }
	</span>;
}

TransText.propTypes = {
	transKey: PropTypes.string.isRequired,
	lang: PropTypes.string.isRequired,
};

export default TransText;