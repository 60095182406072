import React from 'react';
import { TransText } from './Translation';
import PropTypes from 'prop-types';

/**
 * This component is slightly misnamed. It shows the introductory text for the users
 *
 */
export function WelcomePane (props) {
	return (
		<div className="welcome-pane">
			<h5>
				<TransText transKey="welcome-title" lang={props.lang}></TransText>
			</h5>
			<p className="mt-3">
				{props.ageGroup === "young" ?
					<TransText transKey="intro-text-young" lang={props.lang} /> :
					<TransText transKey="intro-text-old" lang={props.lang} /> }
			</p>
			<p className="mt-3">
				<TransText transKey="intro-text-2" lang={props.lang} />
			</p>
			<p className="mt-3">
				<TransText transKey="intro-pane-continue" lang={props.lang} />
			</p>
		</div>
	);
}

WelcomePane.propTypes = {
	/**
	 * Language selected by the user
	 */
	lang: PropTypes.string.isRequired,
	/**
	 * Age group selected by the user ('young' or 'old')
	 */
	ageGroup: PropTypes.string.isRequired,
};
