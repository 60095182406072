export const deTrans = {
	// buttons
	"button-next": "Weiter",
	"button-back": "Zurück",
	"button-next-quiz": "Antwort abschicken",
	"button-complete-quiz": "Antworten abschicken und den Kurs abschließen",
	"button-complete-review": "Kurs abschließen",
	"button-complete-survey": "Antworten abschicken und beenden",
	// app name
	"app-name": "Hemingway",
	"app-subtitle": "eine Betrugs- und Phishing-Herausforderung von Norton Research",
	// welcome titles
	"welcome-title": "Herzlich willkommen!",
	"welcome-back-title": "Wilkommen zurück",
	// language select
	"lang-select-text": "Bitte wählen Sie Ihre Sprache",
	// pre-welcome pane (age group)
	"start-age-group": "Bitte wählen Sie Ihre Altersgruppe.",
	"under-25": "Ich bin 50 Jahre oder jünger",
	"over-25": "Ich bin über 50 Jahre alt",
	// welcome pane
	"intro-text-old": "Ihre gute Freundin und Nachbarin, Barbara Bauer, bittet Sie oft, auf ihr Haus aufzupassen, wenn sie nicht da ist. Sie verreist für mehrere Wochen ins Ausland, ohne Zugang zu ihrem Computer, und hat Sie gebeten, sich um ihre Angelegenheiten zu kümmern, während sie weg ist. Sie hat Ihnen die Schlüssel zu ihrer Wohnung gegeben, in der sie ein Notizbuch mit ihren Passwörtern für verschiedene Konten aufbewahrt.",
	"intro-text-young": "Ihre Großtante Barbara Bauer überlässt Ihnen ihr Haus und ihren Computer, wenn sie nicht in der Stadt ist. Da sie diesmal für mehrere Wochen verreist, hat sie Sie gebeten, auf ihren Posteingang aufzupassen und sich um alle E-Mails zu kümmern, die bearbeitet werden müssen. Sie hat Ihnen auch gezeigt, wo sie ein Notizbuch mit den Passwörtern für ihre verschiedenen Konten aufbewahrt.",
	"intro-text-2": "Ihre Aufgabe heute ist es, E-Mails für Ihre Großtante mit Hilfe des Computers zu bearbeiten. Sie wird später anrufen und Sie können sie dann über allen wichtigen Ereignisse informieren.",
	"intro-pane-continue": 'Klicken Sie auf "Weiter", um anzufangen.',
	"legal-blurb": "Alle hier gezeigten E-Mails, sowohl Betrugsmails als auch legitime Korrespondenz, stammen aus den Posteingängen echter Menschen und wurden nur so verändert, dass persönliche Informationen entfernt wurden und sie in das Quizformat passen.  Markennamen, Logos, Warenzeichen und Dienstleistungsmarken, die in den E-Mails erscheinen, sind Eigentum der jeweiligen Unternehmen, stehen in keiner Verbindung zu NortonLifeLock und werden hier ausschließlich zu Schulungszwecken aufgeführt.",
	// resume-pane
	"resume-pane-text": "Es sieht so aus, als hätten Sie den Kurs bereits begonnen. Möchten Sie dort weitermachen, wo Sie aufgehört haben?",
	"resume-pane-clear": "Vorherige Antworten löschen und neu beginnen",
	"resume-pane-continue": "Weitermachen, wo ich aufgehört habe",
	// email pane
	"default-question-title": "Barbara erhielt die folgende E-Mail",
	"email-reply-button": "Antworten",
	"email-forward-button": "Weiterleiten",
	"email-delete-button": "Loschen",
	"email-from-field": "Von:",
	"email-to-field": "An:",
	// quiz pane
	"quiz-answer-followup-explain": "Erläutern",
	"quiz-answer-followup-why": "Warum?",
	"quiz-answer-followup-how": "Wie?",
	"quiz-choose-1-answer": "Wählen Sie eine Antwort",
	"links-disabled-msg": "Links deaktiviert für Hemingway. Normalerweise hätte dies eine neue Webseite geöffnet.",
	"links-attachments-disabled-msg": "Links und Anhänge deaktiviert für Hemingway. Dieser Klick würde herunterladen ",
	// quiz complete pane
	"quiz-complete-1": "Sie haben alle Übungen des Scam Defender Hindernisparcours absolviert.",
	"quiz-complete-review": "Anschließend werden Sie die Übungen durchgehen und feststellen, welche davon Betrug waren. Alle E-Mails, sowohl die seriösen als auch die betrügerischen, sind Beispiele aus der realen Welt. Bei der Überprüfung werden Sie lernen, anhand welcher Aspekte Sie erkennen können, ob die jeweilige E-Mail legitim ist.",
	"quiz-complete-compliments": "Herzlichen Glückwunsch!",
	// review pane
	"review-expl-title": "Erläuterung der korrekten Antwort",
	// review complete pane
	"review-complete-compliments": "Sehr gut!",
	"review-complete": "Sie haben die Überprüfung abgeschlossen, welche E-Mails Betrug und welche legitim waren.",
	"review-incomplete": "Wir haben nur noch ein paar Fragen an Sie.  Bitte klicken Sie auf \"Weiter\", um fortzufahren.",
	// review error
	"quiz-complete-error-submit-1": "Es gab ein Problem bei der Übermittlung der Antworten, bitte fragen Sie nach Hilfe.",
	"quiz-complete-error-submit-2": "Bei der Übermittlung Ihrer Umfrageantworten ist ein Problem aufgetreten, bitte fragen Sie nach Hilfe.",
	// thanks pane
	"thanks-pane-1": "Vielen Dank für Ihre Teilnahme! Wir hoffen, dass Sie diesen Kurs hilfreich fanden.",
	"user-feedback-ask": "Wenn Sie möchten, können Sie unten ein Feedback hinterlassen. Ansonsten können Sie dieses Fenster schließen.",
	"user-feedback-placeholder": "Bitte geben Sie Ihr Feedback hier ein",
	"user-feedback-submitted-success": "Vielen Dank, Ihr Feedback wurde übermittelt",
	"user-feedback-submitted-error": "Beim Absenden Ihres Feedbacks ist ein Fehler aufgetreten",
	"user-feedback-submit-btn": "Feedback abgeben",
	// thanks pane email signup (not currently supported)
	"user-email-signup-text": "TODO",
	"user-email-signup-btn": "TODO",
	// survey
	"survey-instructions-0": "Wir von der Norton Research Group untersuchen, welche Betrugsversuche am schwersten für Menschen zu erkennen sind. " + "Wenn Sie uns die Antworten, die Sie in der vorherigen Übung eingegeben haben, zur Verfügung stellen, helfen Sie uns bei der Entwicklung von Gegenmaßnahmen, " + "die hoffentlich dazu beitragen, dass die Menschen nicht von skrupellosen Betrügern ausgenutzt werden.  Wir erfassen nicht Ihren" +  " Namen noch andere identifizierende Informationen, so dass Ihre Teilnahme an der Studie völlig anonym ist.",
	"survey-instructions-1": "Um die Ergebnisse unserer Untersuchung in den richtigen Kontext zu setzen, möchten wir Ihnen einige demografische Fragen stellen sowie Fragen zu Ihren Gewohnheiten bei der Nutzung von Computergeräten.",
	"survey-instructions-2": "Auch hier gilt: Ihre Antworten sind anonym und können nur von Forschern unserer Gruppe eingesehen werden.",
	"survey-instructions-3": "Die Beantwortung der folgenden Fragen ist natürlich freiwillig. Sie können einzelne oder alle Fragen auslassen.",
	"survey-consent-q":  "Können wir Ihre Antworten auf das Hemingway-Quiz in unserer Forschungsstudie verwenden?",
	"survey-consent-yes": "Ja",
	"survey-consent-no": "Nein"
};
