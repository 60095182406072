import React from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlay } from '@fortawesome/free-solid-svg-icons';

import { TransText } from './Translation';

/**
 * Expect these props:
 * 	- lang: string
 * 	- onResume -> continue where you left off
 *  - onClear -> clear responses and start again
 */
export function ResumePane(props) {
	return (<div className="resume-pane">
		<h5>
			<TransText transKey="welcome-back-title" lang={props.lang} />
		</h5>
		<p className="mt-3">
			<TransText transKey="resume-pane-text" lang={props.lang} />
		</p>
		<ButtonToolbar>
			<Button variant="outline-secondary" size="lg" className="m-3" onClick={props.onClear} >
				<span>
					<FontAwesomeIcon icon={faTrash} className="mr-2" />
					<TransText transKey="resume-pane-clear" lang={props.lang} />
				</span>
			</Button>

			<Button variant="outline-primary" size="lg" className="m-3" onClick={props.onResume}>
				<span>
					<FontAwesomeIcon icon={faPlay} className="mr-2" />
					<TransText transKey="resume-pane-continue" lang={props.lang} />
				</span>
			</Button>
		</ButtonToolbar>
	</div>);
}

ResumePane.propTypes = {
	lang: PropTypes.string.isRequired,
	onResume: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
};