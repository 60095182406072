import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EmailPane } from './EmailPane';
import { Config } from './config';
import { TransText } from './Translation';

// An object to map the names of types that we may deserialize
// to the type itself.
const revivableTypes = {};

// Add a type to our revivable map. If you want to be able to 
// serialize instances of a class, call this immediately after the 
// class definition.
function addRevivableType(typename, clazz) {
	// console.log(`Mapping ${typename}`);
	revivableTypes[typename] = clazz;
}

// Extend from either RevivableType or RevivableComponentType 
// in order to store a string representation of your type name
// to aid in deserialization.
class RevivableType {
	constructor(typename) {
		this._type = typename;
	}
}

class RevivableComponentType extends React.Component {
	constructor(props, typename) {
		super(props);
		this._type = typename;
	}
}

export class Response extends RevivableType {
	constructor() {
		super('Response');
	}
	getPrimaryResponse() {
		return "";
	}
	getSecondaryResponse() {
		return "";
	}
	isComplete() {
		return this.getPrimaryResponse() !== "";
	}
}
addRevivableType('Response', Response);

export class ResponseNotNeeded extends Response {
	constructor() {
		super();
		this._type = 'ResponseNotNeeded';
	}

	isComplete() {
		return true;
	}
}
addRevivableType('ResponseNotNeeded', ResponseNotNeeded);

export class TextResponse extends Response {
	constructor(txt) {
		super();
		this._type = 'TextResponse';
		this.txt = txt;
	}
	getPrimaryResponse() {
		return this.txt;
	}
}
addRevivableType('TextResponse', TextResponse);

export class OptionResponse extends TextResponse {
	constructor(primary, secondary, requiresExpl, answerIndex) {
		super(primary);
		this._type = 'OptionResponse';
		this.explanation = secondary;
		this.requiresExpl = requiresExpl;
		this.answerIndex = answerIndex;
	}
	getSecondaryResponse() {
		return this.explanation;
	}
	isComplete() {
		return this.getPrimaryResponse() !== "" && (!this.requiresExpl || !Config.isFollowupEnabled || this.getSecondaryResponse() !== "");
	}
}
addRevivableType('OptionResponse', OptionResponse);

export class OptionResponses extends RevivableType {
	constructor(responses) {
		super('OptionResponses');
		this.responses = responses;
	}
	getMatchingResponse(txt) {
		const match = this.responses.filter(r => r.getPrimaryResponse() === txt);
		if (match.length === 0) {
			return null;
		}
		return match[0];
	}
	addOrUpdateResponse(newResponse) {
		const otherResponses = this.responses.filter(r => r.getPrimaryResponse() !== newResponse.getPrimaryResponse());
		return new OptionResponses(otherResponses.concat(newResponse));
	}
	removeResponse(toRemove) {
		return new OptionResponses(this.responses.filter(r => r.getPrimaryResponse() !== toRemove.getPrimaryResponse()));
	}
	isComplete() {
		return this.responses.length > 0 && !this.responses.map(r => r.isComplete()).includes(false);
	}
	getResponsesAsStringArray() {
		return this.responses.map(r => r.getPrimaryResponse());
	}
	getGridResponsesForRowAsStringArray(rowkey) {
		return this.responses.filter(r => (r.getPrimaryResponse().indexOf(rowkey + ",") === 0)).map(r => (r.getPrimaryResponse()));
	}
}
addRevivableType('OptionResponses', OptionResponses);

export class AnswerOption extends RevivableType {
	constructor(answer, isCorrect, isWrong, answerDisplay) {
		super('AnswerOption');
		this.answer = answer;
		this.isCorrect = isCorrect || false;
		this.isWrong = isWrong || false;
		this.answerDisplay = answerDisplay || answer;
	}

	getAnswerText() {
		return this.answer;
	}

	getAnswerDisplayText() {
		return this.answerDisplay;
	}

	hasFollowup() {
		return false;
	}

	getFollowupQuestion() {
		return "";
	}
}
addRevivableType('AnswerOption', AnswerOption);

export class AnswerOptionWithFollowup extends AnswerOption {
	constructor(answer, followupQuestion, isCorrect, answerDisplay) {
		super(answer, isCorrect, answerDisplay);
		this._type = 'AnswerOptionWithFollowup';
		this.followupQuestion = followupQuestion;
	}

	hasFollowup() {
		return true;
	}

	getFollowupQuestion() {
		return this.followupQuestion;
	}
}
addRevivableType('AnswerOptionWithFollowup', AnswerOptionWithFollowup);

export class AnswerOptions extends RevivableType {

	constructor() {
		super('AnswerOptions');
	}

	getUpdatedResponse(oldResponse, changeEvent) {
		return new TextResponse(changeEvent.target.value);
	}

	getEmptyAnswer() {
		return new TextResponse("");
	}

	render(resp, updateAnswer) {
		return (
			<Form.Control as="textarea" onChange={e => {
				updateAnswer(this.getUpdatedResponse(resp, e));
			}}
			value={resp.getPrimaryResponse()} />
		);
	}
}
addRevivableType('AnswerOptions', AnswerOptions);


export class AnswerOptionsNone extends AnswerOptions {
	constructor() {
		super();
		this._type = 'AnswerOptionsNone';
	}

	getEmptyAnswer() {
		return new ResponseNotNeeded();
	}
}
addRevivableType('AnswerOptionsNone', AnswerOptionsNone);

export class MultipleChoiceAnswerOptions extends AnswerOptions {

	/**
	 * @param {arary} choices Choices can be:
	 * - an array of AnswerOption (or subclasses)
	 * - an array of strings
	 * - an array of objects which has some basic properties
	 * - or a mix of the 3 above
	 * @param {string | null} questionId Optional question ID
	 */
	constructor(choices, questionId) {
		super();
		this._type = 'MultipleChoiceAnswerOptions';
		this.idPref = questionId ? questionId + "~~" : (Math.random() * 1000 | 0) + "~~";
		this.choices = choices.map(
			c => {
				if (c instanceof AnswerOption) {
					return c;
				} else if (typeof(c) === "string" || c instanceof String) {
					return new AnswerOption(c);
				} else if ('followupQuestion' in c && Config.isFollowupEnabled) {
					return new AnswerOptionWithFollowup(c.answer, c.followupQuestion, c.isCorrect);
				} else {
					return new AnswerOption(c.answer, c.isCorrect, c.isWrong, c.answerDisplay);
				}
			}
		);
	}

	idtoval(id) {
		return id.substring(this.idPref.length);
	}

	render(resp, updateAnswer, inReviewMode) {
		return this.choices.map((answer, idx) => {

			const requiresExplanation = answer.hasFollowup();
			const thisChoice = answer.getAnswerText();
			const thisChoiceDisplay = answer.getAnswerDisplayText();
			const oneResp = resp instanceof OptionResponses ? resp.getMatchingResponse(thisChoice) : resp;
			// true iff this choice is the one the user selected
			const isChecked = oneResp ? oneResp.getPrimaryResponse() === thisChoice : false;
			let explComponent = <div className="d-none" />;

			const labelClass = inReviewMode && answer.isCorrect ? "h6 text-success font-weight-bold" : "h6";
			//const checkDivClass = inReviewMode && answer.isCorrect ? "border border-success" : "";
			let extraIcon = <></> ;
			if (inReviewMode && isChecked) {
				if (answer.isCorrect) {
					extraIcon = <span className="h4 text-success ml-3 correct-answer-icon">{'\u2713'}</span> ;
				}
				if (answer.isWrong) {
					extraIcon = <span className="h4 text-danger ml-3 wrong-answer-icon"> {'\u2718'} </span> ;
				}
			} else if (inReviewMode) {
				extraIcon = <span className="h4 text-success mr-2">&nbsp;</span>;
			}
			if (requiresExplanation && isChecked && Config.isFollowupEnabled) {
				const explanationQuestion = answer.getFollowupQuestion();
				explComponent = (
					<div className="ml-4" key={"dexpl" + thisChoice}>
						{explanationQuestion}
						<Form.Control as="textarea"
							id={"expl" + thisChoice}
							key={"expl" + thisChoice}
							onChange={e => {
								updateAnswer(this.getUpdatedResponse(resp, e, true, idx));
							}}
							value={oneResp.getSecondaryResponse()}
							disabled={inReviewMode}
						/>
					</div>
				);
			}
			return (
				<div key={"dcheck" + thisChoice} className="mb-3">
					<Form.Check className="" id={this.idPref + thisChoice} key={thisChoice}>
						<Form.Check.Input className="mt-1" type={this instanceof SelectOneAnswerOptions ? 'radio' : 'checkbox'}
							key={"ci" + thisChoice}
							checked={isChecked}
							onChange={e => {
								updateAnswer(this.getUpdatedResponse(resp, e, requiresExplanation, idx));
							}}
							disabled={inReviewMode} />
						<Form.Check.Label className="ml-1" key={"cl" + thisChoice}>
							<span className={labelClass}>{thisChoiceDisplay}</span>
							{extraIcon}
						</Form.Check.Label>
					</Form.Check>
					{explComponent}
				</div>
			);
		});
	}

}
addRevivableType('MultipleChoiceAnswerOptions', MultipleChoiceAnswerOptions);

export class SelectAllAnswerOptions extends MultipleChoiceAnswerOptions {
	constructor(choices) {
		super(choices);
		this._type = 'SelectAllAnswerOptions';
	}
	getEmptyAnswer() {
		return new OptionResponses([]);
	}
	getUpdatedResponse(oldResponse, changeEvent, requiresExpl, answerIndex) {
		if (changeEvent.target.id.indexOf("expl") === 0) {
			const primary = changeEvent.target.id.substring(4);
			return oldResponse.addOrUpdateResponse(new OptionResponse(primary, changeEvent.target.value, requiresExpl, answerIndex));
		} else if (changeEvent.target.checked) {
			return oldResponse.addOrUpdateResponse(new OptionResponse(this.idtoval(changeEvent.target.id), "", requiresExpl, answerIndex));
		} else {
			return oldResponse.removeResponse(new OptionResponse(this.idtoval(changeEvent.target.id)));
		}
	}
	render(resp, updateAnswer, inReviewMode) {
		return (
			<div>
				<div className="text-secondary text-uppercase mb-1"><small>Select all appropriate answers</small></div>
				{super.render(resp, updateAnswer, inReviewMode)}
			</div>
		);
	}
}
addRevivableType('SelectAllAnswerOptions', SelectAllAnswerOptions);

export class SelectOneAnswerOptions extends MultipleChoiceAnswerOptions {
	constructor(choices) {
		super(choices);
		this._type = 'SelectOneAnswerOptions';
	}
	getEmptyAnswer() {
		return new OptionResponse("");
	}
	getUpdatedResponse(oldResponse, changeEvent, requiresExpl, answerIndex) {
		if (changeEvent.target.id.indexOf("expl") === 0) {
			const primary = changeEvent.target.id.substring(4);
			return new OptionResponse(primary, changeEvent.target.value, requiresExpl, answerIndex);
		} else if (changeEvent.target.checked) {
			console.log("checking " + changeEvent.target.id);
			return new OptionResponse(this.idtoval(changeEvent.target.id), "", requiresExpl, answerIndex);
		} else {
			return new OptionResponse("", "", requiresExpl);
		}
	}
	render(resp, updateAnswer, inReviewMode, lang) {
		if (!lang) {
			throw new Error('language must be specified');
		}
		return (
			<div>
				<div className="text-secondary text-uppercase mb-1">
					<small><TransText lang={lang} transKey={'quiz-choose-1-answer'} /></small>
				</div>
				{super.render(resp, updateAnswer, inReviewMode)}
			</div>
		);
	}
}
addRevivableType('SelectOneAnswerOptions', SelectOneAnswerOptions);

export class FauxLink extends React.Component {
	constructor(props) {
		super(props);
		this.renderTooltip = this.renderTooltip.bind(this);
	}

	renderTooltip(props) {
		return <Tooltip {...props}>{this.props.url}</Tooltip>;
	}

	blackhole(e) {
		e.preventDefault();
	}

	render() {
		return (
			<OverlayTrigger overlay={this.renderTooltip}
				delay={{ show: 200, hide: 200 }} placement="bottom">
				<a href={this.props.url} onClick={this.blackhole}>{this.props.displaytext}</a>
			</OverlayTrigger>
		);
	}
}

FauxLink.propTypes = {
	url: PropTypes.string.isRequired,
	displaytext: PropTypes.string.isRequired,
};

export class QuestionContext extends RevivableComponentType {
	constructor(props) {
		super(props, 'QuestionContext');
	}
	static revive(key, val) {
		return <QuestionContext {...val} />;
	}
	render() {
		let titleComponent = <div className="d-none"/>;
		if (this.props.title) {
			titleComponent = <p className="quiz-question-context-title mb-3">{this.props.title}</p>;
		}
		let bodyComponent = <div className="d-none" />;
		if (this.props.url) {
			bodyComponent = <iframe src={this.props.url} width="100%" className="border border-primary"></iframe>;
		} else if (this.props.imgurl) {
			bodyComponent = <img src={this.props.imgurl}  />;
		}
		return <>{titleComponent}{bodyComponent}</>;
	}
}
addRevivableType('QuestionContext', QuestionContext);

QuestionContext.propTypes = {
	// optional
	title: PropTypes.string,
	// optional
	url: PropTypes.string,
	// optional
	imgurl: PropTypes.string,
};


export class QuizQuestionDisplay extends React.Component {


	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="quiz-question-display">
				<div className={"text-primary mt-2 quiz-question-title " + this.props.qsize}>{this.props.question}</div>
				{this.props.answers.render(
					// resp
					this.props.response,
					// updateAnswer
					this.props.updateResponse,
					// inReviewMode
					this.props.inReviewMode,
					// lang
					this.props.lang,
				)}
			</div>
		);
	}
}

QuizQuestionDisplay.propTypes = {
	// TODO I have no idea what type this should be
	answers: PropTypes.any.isRequired,
	// TODO I have no idea what type this should be
	question: PropTypes.any.isRequired,
	// TODO I have no idea what type this should be
	response: PropTypes.any.isRequired,
	qsize: PropTypes.string.isRequired,
	updateResponse: PropTypes.func.isRequired,
	inReviewMode: PropTypes.bool.isRequired,
	/**
	 * Language set by the user
	 */
	lang: PropTypes.string.isRequired,
};

export class QuizContent extends RevivableType {
	constructor() {
		super('QuizContent');
	}
}
addRevivableType('QuizContent', QuizContent);

export class Instructions extends QuizContent {
	/**
	 * @param {string} txt
	 */
	constructor(txt) {
		super();
		this._type = 'Instructions';
		this.instructionText = txt;
	}

	isNonQuestion() {
		return true;
	}

	getEmptyAnswer() {
		return new ResponseNotNeeded();
	}

	render() {
		return <p className="mb-3">{this.instructionText}</p>;
	}

}
addRevivableType('Instructions', Instructions);

export class QuizQuestion extends QuizContent {
	constructor(context, question, answerOptions, reviewText) {
		super();
		this._type = 'QuizQuestion';
		this.context = context;
		this.question = question;
		this.answerOptions = answerOptions;
		this.reviewText = reviewText;
	}

	toJSON() {
		console.log("serializing without components and state");
		const clone = {...this};
		clone.context = { props: this.context.props };
		return clone;
	}

	static revive(key, val) {
		if (val.context && val.context.props) {
			switch(val.context.props.contextType) {
				case 'Email':
					val.context = <EmailPane {...val.context.props} /> ;
					break;
				case 'Image':
					val.context = <QuestionContext {...val.context.props} /> ;
					break;
				default:
					console.err("Failed to deserialize question context.");
			}
		}
		Object.setPrototypeOf(val, QuizQuestion.prototype);
		return val;
	}

	isNonQuestion() {
		return false;
	}

	getEmptyAnswer() {
		return this.answerOptions.getEmptyAnswer();
	}

	render(currentResponse, responseUpdatedHandler, inReviewMode, qsize = 'h3', lang = 'en') {
		let reviewExplanationComponent = <div className="d-none"></div>;
		if (inReviewMode) {
			reviewExplanationComponent =
			<Col sm={true} className="review-expl-pane">
				<div className={"mt-2 review-explanation-title text-primary " + qsize}>
					<TransText lang={lang} transKey={"review-expl-title"} />
				</div>
				<p className="review-explanation-text">{inReviewMode ? this.reviewText : ""}</p>
			</Col>;
		}

		return (<>
			<Row>
				<Col>
					{this.context}
				</Col>
			</Row>
			<Row className="bg-light mx-1 mt-2">
				<Col sm={true}>
					<QuizQuestionDisplay question={this.question}
						answers={this.answerOptions}
						response={currentResponse}
						updateResponse={responseUpdatedHandler}
						inReviewMode={inReviewMode}
						qsize={qsize}
						lang={lang}
					/>
				</Col>
				{reviewExplanationComponent}
			</Row>
		</>
		);
	}
}
addRevivableType('QuizQuestion', QuizQuestion);

export function reviveMyTypes(key, val) {
	if (val && typeof(val) === 'object' && '_type' in val) {
		try{
			const tp = revivableTypes[val._type];
			if ('revive' in tp && typeof(tp.revive) === 'function') {
				console.debug("Calling revive method to deserialize object of type " + val._type);
				return tp.revive(key, val);
			}
			if ('prototype' in tp) {
				console.debug("Setting prototype of deserialize object of type " + val._type);
				Object.setPrototypeOf(val, tp.prototype);
			}
		} catch(e) {
			console.error("Failed to deserialize object with _type " + val._type + ", error is " + e);
		}
	}
	return val;
}


/**
 * @param {string} lang Language for the quiz questions
 * @returns {any}
 */
export async function fetch_quiz_content(lang) {
	if (!lang) {
		throw new Error('language is required');
	}
	const doc = `questions_newformat_${lang}.json`;
	const resp = await fetch(doc);
	if (!resp.ok) {
		throw new Error(`failed to fetch quiz questions with lang=${lang}`);
	}
	console.log(`Fetched quiz questions with lang=${lang}`);
	const jsonText = await resp.text();
	return  JSON.parse(jsonText, reviveMyTypes);
	//.catch( err => { console.log("Retrieving quiz content failed with:"); console.log(err);});
}