/**
 * This is a central place to define the Hemingway API
 * Allows for easier one-stop changes
 * All the methods should return a Promise as returned by the fetch API
 */

import { Config } from './config';

/**
 * Gently ping the server
 * @returns {Promise<Response>}
 */
export async function getServerUp() {
	const url = new URL(Config.serverUrl);
	url.pathname = '/';
	return fetch(url, {
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		}
	});
}

/**
 * @param {any} submission  - object that must include at least these keys:
 * 		- uuid
 * 		- lang
 * 		- responses
 * 		- startTimestamp
 * 		- endTimestamp
 * @returns {Promise<Response>}
 */
export async function postQuizResponses(submission) {
	// sanity checking
	const requiredParams = ['uuid', 'lang', 'responses', 'startTimestamp', 'endTimestamp'];
	for (const param of requiredParams) {
		if (!(param in submission)) {
			throw new Error(`parameter ${param} is required`);
		}
	}

	const url = new URL(Config.serverUrl);
	url.pathname = '/submit/quiz';
	return fetch(url, {
		method: 'POST',
		headers: { 'Content-Type' : 'application/json' },
		body: JSON.stringify(submission),
		mode: 'cors',
	});
}

/**
 * @param {any} submission  - object that must include at least these keys:
 * 		- uuid
 * 		- lang
 * 		- surveyResponses
 * @returns {Promise<Response>}
 */
export async function postSurveyResponses(submission) {
	// sanity checking
	const requiredParams = ['uuid', 'lang', 'surveyResponses'];
	for (const param of requiredParams) {
		if (!(param in submission)) {
			throw new Error(`parameter ${param} is required`);
		}
	}

	const url = new URL(Config.serverUrl);
	url.pathname = '/submit/survey';
	return fetch(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(submission),
		mode: 'cors',
	});
}

/**
 * @param {string} uuid
 * @param {string} lang
 * @param {string} feedbackText
 * @returns {Promise<Response>}
 */
export async function postUserFeedback(uuid, lang, feedbackText) {
	// sanity checking
	if (!uuid) {
		throw new Error('uuid is required');
	}
	if (!lang) {
		throw new Error('language is required');
	}
	if (!feedbackText) {
		throw new Error('feedback text is required');
	}

	// copy over string length restrictions from server
	if (lang.length > 10) {
		throw new Error('language must not be longer than 10 characters');
	}
	if (feedbackText.length > 1024) {
		throw new Error('feedbackText must not be longer than 1024 characters');
	}

	const url = new URL(Config.serverUrl);
	url.pathname = '/submit/user-feedback';
	const data = {
		uuid: uuid,
		lang: lang,
		feedback: feedbackText,
	};
	return fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});
}
