import React from 'react';
import { Instructions, QuizQuestion, SelectOneAnswerOptions, OptionResponse } from './QuestionsAndAnswers.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import PropTypes from 'prop-types';
import { TransText, translateString } from './Translation.jsx';


function SurveyNavigation(props) {
	return (
		<ButtonToolbar>
			<Button variant="outline-secondary" size="lg" className="mr-4 mb-2"
				onClick={props.onBack}
				disabled={!props.backEnabled}>
				Go Back
			</Button>

			<Button variant="primary" size="lg" className="mr-4 mb-2"
				disabled={!props.submitEnabled} onClick={props.onSubmit}>
				{props.submitButtonText}
			</Button>
		</ButtonToolbar>
	);
}

SurveyNavigation.propTypes = {
	onBack: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	backEnabled: PropTypes.bool.isRequired,
	submitEnabled: PropTypes.bool.isRequired,
	submitButtonText: PropTypes.string.isRequired,
};

/**
 * Return the survey questions for this language
 * @param {string} lang Language
 * @returns {Promise<any>} promise that resolves to the survey
 * @throws Throw an error on failure
 */
async function fetchSurveyQuestions(lang) {
	if (!lang) {
		throw new Error("language must be set here");
	}

	const doc = `survey_${lang}.json`;
	const resp = await fetch(doc);
	if (!resp.ok) {
		throw new Error(`failed to fetch survey questions with lang=${lang}`);
	}
	const survey = await resp.json();
	console.log(`Fetched ${survey.length} survey questions with lang=${lang}`);
	return survey;
}

function SurveyQuestion(props) {
	let answerOptions = null;
	switch (props.question.type) {
		case 'SelectOne': {
			const handleSelectChange = (e) => {
				const val = e.target.value;
				props.onChange(props.questionNum, val);
			};
			const answerChoices = ['', ...props.question.answerChoices];
			const options = answerChoices.map((optionText, i) => {
				return <option value={optionText} key={`option-${i}`}>{optionText}</option>;
			});
			answerOptions = <div>
				<div className="text-secondary text-uppercase mb-1"><small>
					<TransText transKey="quiz-choose-1-answer" lang={props.lang} />
				</small></div>
				<select className="form-control" onChange={handleSelectChange} value={props.selectedOption} required={true}>
					{options}
				</select>
			</div>;
			break;
		}
		case 'RadioButtonSelectOne': {
			const radioButtons = props.question.answerChoices.map((optionText, i) => {
				const radioButtonId = props.question.key + '-option' + i;
				return <div className="mb-3" key={`option-${i}`}>
					<div className='form-check'>
						<input type="radio" className="mt-1 form-check-input" name={props.question.key}
							onChange={() => props.onChange(props.questionNum, optionText) }
							value={optionText}
							checked={optionText === props.selectedOption}
							id={radioButtonId} />
						<label htmlFor={radioButtonId} className="ml-1 form-check-label">
							<span className="h6">{ optionText }</span>
						</label>
					</div>
					{/* <div className='d-none'></div> */}
				</div>;
			});
			answerOptions = <div>
				<div className="text-secondary text-uppercase mb-1"><small>
					<TransText transKey="quiz-choose-1-answer" lang={props.lang} />
				</small></div>
				{ radioButtons }
			</div> ;
			break;
		}
		case 'FreeFormText': {
			const handleTextInputChange = (e) => {
				const val = e.target.value;
				props.onChange(props.questionNum, val);
			};
			answerOptions = <input type="text" className="form-control" name={props.question.key}
				required={true} maxLength={props.question.maxLength} autoComplete={props.question.autoComplete}
				onChange={handleTextInputChange} />;
			break;
		}
		default:
			throw new Error(`Unknown question type: ${props.question.type}`);
	}

	return (<div className="survey-question bg-light mx-1 mt-2 row" id={`survey-question-${props.questionNum}`}>
		<div className="my-2 col">
			<div className="survey-question-text text-primary mt-1 h4">{ props.question.questionText }</div>
			{ answerOptions }
		</div>
	</div>);
}

SurveyQuestion.propTypes = {
	/**
	 * Language in which to present the question
	 */
	lang: PropTypes.string.isRequired,
	/**
	 * Question number
	 */
	questionNum: PropTypes.number.isRequired,
	/**
	 * The question for specifics see survey_en.json
	 * - required: type, questionText
	 * - optional (for text only): maxLength, autoComplete
	 */
	question: PropTypes.object.isRequired,
	/**
	 * Call this method when selection has changed
	 */
	onChange: PropTypes.func.isRequired,
	/**
	 * The currently selected option (where relevant)
	 */
	selectedOption: PropTypes.string,
};

/**
 * This is the survey that we show users at the end of our quiz
 */
export class Survey extends React.Component {

	constructor(props) {
		super(props);

		const inst = new Instructions(translateString(this.props.lang, "survey-instructions-0"));
		this.q1 = new QuizQuestion(inst.render(), translateString(this.props.lang, "survey-consent-q"),
			new SelectOneAnswerOptions([
				translateString(this.props.lang, "survey-consent-yes"),
				translateString(this.props.lang, "survey-consent-no"),
			])
		);

		this.surveyForm = React.createRef();

		this.state = {
			questionNum: 0,
			willParticipate: new OptionResponse("Yes"),
			/**
			 * A list of the survey questions
			 */
			surveyQuestions: [],
			/**
			 * This is the language for which the survey questions have been fetched
			 * Set to null if they haven't been fetched for any language
			 */
			surveyQuestionsFetchedLang: null,

			/**
			 * Survey responses array which corresponds 1:1 to the surveyQuestions
			 */
			responses: [],

			/**
			 * Whether the current survey form is valid.
			 * This is derived from the current survey question and answer
			 */
			isFormValid: true,
		};

		// bind because javascript is terrible
		this.updateAnswerOnChange = this.updateAnswerOnChange.bind(this);
		this.updateWillParticipate = this.updateWillParticipate.bind(this);
		this.goNext = this.goNext.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	/**
	 * Fetch survey questions the first time
	 */
	componentDidMount() {
		console.log("Fetching survey questions on initial render");
		fetchSurveyQuestions(this.props.lang).then((surveyQuestions) => {
			this.setState({
				surveyQuestions: surveyQuestions,
				surveyQuestionsFetchedLang: this.props.lang,
			});
		});
	}

	/**
	 * Respond to props changes
	 * NOTE: not called for the initial render
	 */
	componentDidUpdate() {
		if (this.state.surveyQuestionsFetchedLang !== this.props.lang) {
			console.log(`Language has changed to ${this.props.lang} and we don't have survey questions for this language`);

			fetchSurveyQuestions(this.props.lang).then((surveyQuestions) => {
				this.setState({
					surveyQuestions: surveyQuestions,
					surveyQuestionsFetchedLang: this.props.lang,
				});
			});
		}
	}

	updateWillParticipate(r) {
		this.setState({willParticipate: r});
	}

	goNext() {
		if (this.state.willParticipate.getPrimaryResponse() === translateString(this.props.lang, "survey-consent-no") ||
			this.state.questionNum === (this.state.surveyQuestions.length + 2) - 1) {
			console.log(JSON.stringify(this.state));
			console.log(this.state);
			this.props.onComplete(this.state);
			return;
		} else{
			this.setState({
				questionNum: this.state.questionNum + 1,
			});
			// scroll up to the top
			window.scroll(0, 0);
		}
	}

	goBack() {
		console.assert(this.state.questionNum > 0);
		this.setState({
			questionNum: this.state.questionNum - 1
		});
	}

	/**
	 * DO NOT advance the question number
	 * This just updates the answer for the given question
	 * This also checks the validity of the form
	 */
	updateAnswerOnChange(questionNum, answer) {
		const newResponses = [];
		Object.assign(newResponses, this.state.responses);
		newResponses[questionNum] = answer;
		console.debug(`updated answer for ${questionNum} -> answer is ${answer}`);
		const isFormValid = this.surveyForm.current.reportValidity();
		this.setState({
			responses: newResponses,
			isFormValid: isFormValid,
		});
	}

	render() {
		let content = <div>Placeholder</div>;
		// by default true
		let nextButtonEnabled = true;

		if (this.state.questionNum === 0) {
			content = this.q1.render(this.state.willParticipate, this.updateWillParticipate, false,
				'h3', this.props.lang);
		} else if (this.state.questionNum === 1) {
			const inst1 = new Instructions(translateString(this.props.lang, "survey-instructions-1"));
			const inst2 = new Instructions(translateString(this.props.lang, "survey-instructions-2"));
			const inst3 = new Instructions(translateString(this.props.lang, "survey-instructions-3"));
			content =
			<>
				{inst1.render()}
				{inst2.render()}
				{inst3.render()}
			</>;
		} else {
			content = (<form ref={this.surveyForm}>
				<SurveyQuestion
					lang={this.props.lang}
					questionNum={this.state.questionNum}
					question={this.state.surveyQuestions[this.state.questionNum - 2]}
					onChange={this.updateAnswerOnChange}
					selectedOption={this.state.responses[this.state.questionNum] || ''} />
			</form>);
			nextButtonEnabled = Boolean(this.state.responses[this.state.questionNum]) && this.state.isFormValid;
		}

		return (
			<div className="survey-pane">
				{content}
				< Row className="my-3" >
					<Col>
						<SurveyNavigation
							onBack={this.goBack}
							backEnabled={this.state.questionNum > 0}
							onSubmit={this.goNext}
							submitEnabled={nextButtonEnabled}
							submitButtonText={this.state.questionNum === (this.state.surveyQuestions.length + 2) - 1 ?
								translateString(this.props.lang, "button-complete-survey") :
								translateString(this.props.lang, "button-next")}
						/>
					</Col>
				</Row >
			</div>
		);
	}
}

Survey.propTypes = {
	/**
	 * The language that the user selected
	 */
	lang: PropTypes.string.isRequired,
	/**
	 * This callback function is called with the survey state as single argument
	 */
	onComplete: PropTypes.func.isRequired,
};