import React, { useState } from 'react';

// App.scss should be first so it takes precedence
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Quiz } from './Quiz';
import { Config } from './config';
import { getServerUp } from './api';


function App() {
	const [isBackendUp, setBackendUp ] = useState(false);
	const [hasPingedBackend, setPingedBackend] = useState(false);

	const pingBackendServer = async () => {
		try {
			const r = await getServerUp();
			if (r.ok) {
				setBackendUp(true);
				setPingedBackend(true);
			} else {
				setBackendUp(false);
				setPingedBackend(true);
			}
		} catch (err) {
			console.error(err);
			setBackendUp(false);
			setPingedBackend(true);
		}
	};

	if (!hasPingedBackend) {
		pingBackendServer();
	}

	let backendDownWarning = null;
	if (Config.pingBackendServerOnStartup && hasPingedBackend && !isBackendUp) {
		backendDownWarning = <div className="alert alert-danger" role="alert">
			<strong>Warning (dev-only)!</strong>
			<p>The backend server is not reachable. Server currently set to {Config.serverUrl}. Refresh the page to check again.</p>
		</div>;
	}

	return (
		<div className="wrapper">
			<header>
				{/* having the header is important for the grid layout */}
				{/* do not remove this element */}
			</header>

			<main>
				{ backendDownWarning }
				<Quiz />
			</main>

			<footer className="page-footer">
				<div className="brand">
					<img src="logo_norton_d.svg" />
				</div>
				<div>Research Group</div>
			</footer>
		</div>
	);
}

export default App;
