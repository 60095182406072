import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import { translateString, TransText } from './Translation';
import Config from './config';
import { postUserFeedback } from './api';


function UserFeedbackBox(props) {
	const [isSubmitted, setSubmitted] = useState(false);
	const [feedbackText, setFeedbackText] = useState('');
	// only meaningful if isSubmitted === true
	const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

	const submitFeedback = async (e) => {
		e.preventDefault();
		console.log('submitting user feedback to backend');

		try {
			const r = await postUserFeedback(props.uuid, props.lang, feedbackText);
			if (r.ok) {
				setSubmitted(true);
				setSubmittedSuccessfully(true);
				console.log('success');
			} else {
				setSubmitted(true);
				setSubmittedSuccessfully(false);
				console.error('oh no');
				console.error(r);
			}
		} catch (err) {
			setSubmitted(true);
			setSubmittedSuccessfully(false);
			console.error('oh no');
			console.error(err);
		}

		return false;
	};

	const handleTextChange = (e) => {
		setFeedbackText(e.target.value);
	};

	return <div className='user-feedback-box-container'>
		{ isSubmitted ?
			<>
				{ submittedSuccessfully ?
					<p className='text-success'><TransText lang={props.lang} transKey="user-feedback-submitted-success" /></p> :
					<p className='text-danger'><TransText lang={props.lang} transKey="user-feedback-submitted-error" /></p> }
			</> :
			<>
				<p><TransText lang={props.lang} transKey={"user-feedback-ask"} /></p>
				<form onSubmit={submitFeedback}>
					<textarea name="user_feedback" className="form-control"
						placeholder={translateString(props.lang, "user-feedback-placeholder")}
						onChange={handleTextChange}
						maxLength={999}
						required={true}></textarea>
					<Button variant="outline-primary" size="lg" className="mt-3" disabled={!feedbackText}
						onClick={submitFeedback}>
						<TransText lang={props.lang} transKey={"user-feedback-submit-btn"} />
					</Button>
				</form>
			</>
		}
	</div>;
}

UserFeedbackBox.propTypes = {
	lang: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
};


function UserEmailEnrollmentForm(props) {
	const [email, setEmail] = useState('');

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const handleSubmit = () => {
		// TODO: user enrollment code is not yet implemented
		throw new Error('this functionality is not yet implemented');
	};

	return (<div className='user-email-enrollment-form'>
		<p>
			<TransText lang={props.lang} transKey="user-email-signup-text" />
		</p>
		<form>
			<input type="email" className="form-control" name="email" autoComplete='email'
				placeholder='email' required={true} onChange={handleChange} />
			<Button variant='outline-primary' size='lg' className='mt-3' disabled={!email}
				onClick={handleSubmit}>
				<TransText lang={props.lang} transKey="user-email-signup-btn" />
			</Button>
		</form>
	</div>);
}

UserEmailEnrollmentForm.propTypes = {
	lang: PropTypes.string.isRequired,
};


/**
 * This component is shown at the very end.
 */
export function ThanksPane(props) {
	return (<div className="thanks-pane">
		<p className="mt-4">
			<TransText transKey="thanks-pane-1" lang={props.lang} />
		</p>
		{ props.isSurveyEnabled && props.surveySubmitError ?
			<p className="text-danger mt-4">
				<TransText transKey="quiz-complete-error-submit-2" lang={props.lang} />
			</p> :
			null }

		{/* TODO show a success message here when the survey responses have been submitted successfully */}

		{ Config.showUserEmailEnrollmentForm ?
			<UserEmailEnrollmentForm /> :
			null }

		{ Config.showUserFeedbackBox ?
			<UserFeedbackBox lang={props.lang} uuid={props.uuid} /> :
			null }

		{ props.onRestart ?
			<div>
				<ButtonToolbar>
					<Button variant="outline-primary" size="lg" className="m-3" onClick={props.onRestart}>
						Restart from beginning
					</Button>
				</ButtonToolbar>
			</div> :
			null}
	</div>);
}

ThanksPane.propTypes = {
	/**
	 * Language selected by the user
	 */
	lang: PropTypes.string.isRequired,
	/**
	 * UUID identifying the quiz
	 */
	uuid: PropTypes.string.isRequired,
	/**
	 * Whether the survey feature at the end is enabled
	 */
	isSurveyEnabled: PropTypes.bool.isRequired,
	/**
	 * Whether the survey has been submitted (if enabled)
	 */
	successfullySubmittedSurvey: PropTypes.bool.isRequired,

	/**
	 * May be a string or null
	 */
	surveySubmitError: PropTypes.string,

	/**
	 * Handle the restart event
	 * If this is null, it means the restart event is not supported
	 */
	onRestart: PropTypes.func,
};